import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import icon from "../Assets/Online Money Transfer.svg";
import coin from "../Assets/Bitcoin.svg";
import tenyear from "../Assets/10 years.svg";
import rating from "../Assets/Rating.png";
import dash from "../Assets/Group 1000005963.png";
import icon2 from "../Assets/explorer-dynamic-colorx.png";
import { Link } from "react-router-dom";
import SliderPage from "./home page/slider/SliderPage";
import GainerAndLooser from "./home page/gainerandlooser/GainerAndLooser";
import Explore from "./home page/Explore";
import ChatBotComponent from "../Components/ChatBotComponent";
import HelmetBase from "../helmet/HelmetBase";
function Mainpage() {
  const [activeTab, setActiveTab] = useState(1);
  const [activeTab2, setActiveTab2] = useState(1);
  const handleTabChange2 = (tabIndex) => {
    setActiveTab2(tabIndex);
  };

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <>
      <HelmetBase
        title={"Crypto24x7: Secure Crypto Trading Made Easy"}
        description={
          "Crypto24x7 offers a seamless and intuitive trading experience. Easily navigate our platform and execute trades with confidence."
        }
      />
      <Navbar />

      {/* <div className='homebanner'>
    <br/>
    <br/>
    <h1>
    Sell  & Buy Crypto <br/>
with ease
<img src={icon}/>
    </h1>
    <p>This is going to be the most exiciting crypto service you have ever tried in your life, keep going </p>
  
    </div> */}
      {/* <div>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          // pagination={{
          //   clickable: true,
          // }}
          // navigation={true}
          // modules={[Autoplay, Pagination, Navigation]}
          // className="mySwiper"
        >
          <SwiperSlide>
            <div className="expscreen">
              <div className="expscreen-left">
                <h1>Explore Screeners</h1>
                <br />
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua ut
                  dolor sit
                </p>
                <br />

                <Link to="/login">
                  <button>Get Started Now</button>
                </Link>
              </div>
              <div className="expscreen-right">
                <img src={dash} />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="expscreen">
              <div className="expscreen-right">
                <img src={dash} />
              </div>
              <div className="expscreen-left">
                <h1>Explore Screeners</h1>
                <br />
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua ut
                  dolor sit
                </p>
                <br />

                <Link to="/login">
                  <button>Get Started Now</button>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div> */}
      {/* -------------------------Home Page Slider ----------------------------- */}
      <SliderPage />
      {/* -------------------------Home Page Slider ----------------------------- */}
      <br />
      <br />

      <GainerAndLooser />

      <br />
      <Explore />

      <br />
      <br />
      <div className="somethingaboutus">
        <br />
        <br />
        <h1>Want to Know something about us</h1>
        <br />
        <br />
        <br />

        <div className="something">
          <div>
            <img src={tenyear} />
          </div>
          <div className="somting2">
            <p>
              Years of Coin yoga <br />
              <span>Unlock the power of Data visualization</span>
            </p>
          </div>

          <div className="somting3">
            <p>
              Lorem ipsum Tempor incididunt ut labore et dolore magna aliquat
              enim veniam quis nostrud exercitation ullamco laboris nis aliquip
              consequat duis aute irure dolor voluptate.
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />

      <div className="rattings">
        <div className="rattings-left">
          <div className="rattings-left-div">
            <div className="rattings-left-text">
              <h1>$12B+</h1>
              <p>
                Assets <br />
                management
              </p>
            </div>
            <br />

            <div className="rattings-left-text">
              <h1>$12B+</h1>
              <p>
                Assets <br />
                management
              </p>
            </div>
          </div>

          <br />
          <div className="rattings-left-div">
            <div className="rattings-left-text">
              <h1>$12B+</h1>
              <p>
                Assets <br />
                management
              </p>
            </div>
            <br />

            <div className="rattings-left-text">
              <h1>$12B+</h1>
              <p>
                Assets <br />
                management
              </p>
            </div>
          </div>
        </div>
        <div className="rattings-right">
          <img src={rating} />
        </div>
      </div>
      <br />

      <div className="portfolio">
        <h1>
          Trusted by 10 Millions+ Users.
          <br />
          Create your crypto portfolio on Crypto
          <br />
          trader <img src={icon2} /> in seconds{" "}
        </h1>
      </div>
      <ChatBotComponent />
      <Footer />
    </>
  );
}

export default Mainpage;

import React from 'react'
import img from '../Assets/image 2 (1).svg'
import logo from '../Assets/newlogo.png'

import google from '../Assets/Google.svg'
import HelmetBase from "../helmet/HelmetBase"

function LoginCode() {
  return (
  <>
    <HelmetBase
      title={"Secure Login to Your Crypto24x7 Crypto Account"}
      description={
        "Log in to your Crypto24x7 account to access your crypto holdings, manage your investments, and start trading!"
      }
    />
      <>
    <div className='login'>
    <div className='login-left'>
    <img className="sing-logo" src={logo}/>

<div  className='login-left-center'>
<img src={img}/>

</div>
</div>
        <div className='login-right'>
        <button className='signup-btn'>Sing Up</button>

<br/>
<br/>
<br/>
<div className='login-form'>
    <h2>Verification code</h2>
    <br/>
    <h3>We just send you a verify code. Check your inbox to get them.</h3>
 <br/>
 <br/>
 <div className='code'>
 <input />
 <input />
 <input />
 <input />


 </div>
 <br/>
 <br/>
 <p className='resend'>Re-send code in <span> 0:20</span></p>
 <br/>
 <br/>
<div className='center'>
<button className='logbtn'>Submit</button>

</div>    
<br/>
   
</div>
        </div>
    </div>


    </>

  </>
  )
}

export default LoginCode